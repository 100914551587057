<template>
  <div>
    <el-form
      label-position="right"
      label-width="20%"
      :model="editFrom"
      :rules="rules"
      ref="ruleForm"
    >
      <h1>编辑</h1>
      <el-form-item label="微信昵称">
        <el-input
          placeholder="请输入微信昵称"
          disabled
          v-model="editFrom.wxUsername"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="username">
        <el-input
          placeholder="请输入姓名"
          v-model="editFrom.username"
        ></el-input>
      </el-form-item>
      <el-form-item label="编号">
        <el-input
          placeholder="请输入用户编号"
          maxlength="8"
          clearable
          disabled
          v-model="editFrom.userNum"
        ></el-input>
      </el-form-item>
      <el-form-item label="年龄：" prop="age">
        <el-input
          v-model.number="editFrom.age"
          placeholder="用户年龄"
          maxlength="3"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="生日：" prop="birthday">
        <el-date-picker
          v-model="editFrom.birthday"
          type="date"
          placeholder="请选择用户出生年月日"
          format="yyyy - MM - dd"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          maxlength="20"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio :label="0" v-model="editFrom.gender">女</el-radio>
        <el-radio :label="1" v-model="editFrom.gender">男</el-radio>
      </el-form-item>
      <el-form-item label="学历" prop="education">
        <el-select
          placeholder="请选择"
          @change="educationChange"
          :value="editFrom.education"
        >
          <el-option
            v-for="(item, index) in educationList"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="目前所在地" prop="state">
        <el-input v-model="editFrom.state"></el-input>
      </el-form-item>
      <el-form-item label="客户备注：" prop="remark">
        <el-input
          type="textarea"
          v-model="editFrom.remark"
          placeholder="请输入客户备注"
          maxlength="100"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item class="last-from-item">
        <el-button type="primary" @click="config">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    editFrom: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      educationList: ["高中及以下", "专科", "本科", "研究生", "博士", "博士后"],
      // 表单校检
      rules: {
        username: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
        // userNum: [
        //   { required: true, message: "请输入用户编号", trigger: "blur" },
        //   {
        //     min: 6,
        //     max: 16,
        //     message: "用户编号长度应为 6 - 16",
        //     trigger: "blur",
        //   },
        // ],
        // age: [
        //   {
        //     required: true,
        //     message: "请填写年龄",
        //     trigger: "blur",
        //   },
        // ],
        gender: [
          { required: true, message: "请选择用户性别", trigger: "blur" },
        ],
        education: [
          { required: true, message: "请选择用户的学历", trigger: "blur" },
        ],
        location: [
          {
            required: true,
            message: "请输入用户的目前所在地",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    educationChange(e) {
      this.editFrom.education = e;
    },
    // 取消按钮
    close() {
      this.$emit("btnClose");
    },
    // 确定按钮
    config() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("succeddEdit", this.editFrom);
        } else {
          this.$message.error("信息填写有误，请检查！");
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
.last-from-item {
  text-align: right;
}
</style>