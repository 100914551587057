<template>
  <div class="appUser">
    <div class="header">
      <div class="search">
        <hearder-input
          @getUserList="searchBywxUsername"
          title="请输入用户微信昵称"
        ></hearder-input>
      </div>
      <div class="search">
        <hearder-input
          @getUserList="searchByusername"
          title="请输入用户姓名"
        ></hearder-input>
      </div>
    </div>
    <template>
      <el-table
        :data="userList"
        style="width: 100%"
        :header-cell-style="headClass"
      >
        <el-table-column prop="wxUsername" label="微信昵称"></el-table-column>
        <el-table-column prop="username" label="姓名"> </el-table-column>
        <el-table-column prop="userNum" label="编号"> </el-table-column>
        <el-table-column prop="age" label="年龄"> </el-table-column>
        <el-table-column prop="gender" label="性别">
          <template slot-scope="scope">{{
            scope.row.gender === 1 ? "男" : "女"
          }}</template>
        </el-table-column>
        <el-table-column prop="education" label="学历"> </el-table-column>
        <el-table-column prop="state" label="目前所在地"> </el-table-column>
        <el-table-column prop="createTime" label="注册时间"> </el-table-column>
        <el-table-column prop="remark" label="客户备注"> </el-table-column>
        <el-table-column label="操作项" align="right">
          <template slot-scope="scope">
            <div class="operation_group">
              <div class="item" @click="extensionEdit(scope.row, scope.$index)">个人资料</div>
              <div class="edit item" @click="preview(scope.row)">预览</div>
              <div class="edit item" @click="handleEdit(scope.row)">编辑</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>
    <my-amend v-show="isAmend" @btnClose="isAmend = false">
      <edit-modul
        :editFrom="editFrom"
        @btnClose="isAmend = false"
        @succeddEdit="succeddEdit1"
      ></edit-modul>
    </my-amend>
    <my-amend v-show="isAmend1" @btnClose="isAmend1 = false">
      <preview-modul :form="previewFrom" @confirmPreview="confirmPreview"></preview-modul>
    </my-amend>
    <!-- 修改个人资料  = false-->
    <my-amend v-show="isAmend2" @btnClose="isAmend3">
      <user-card
        ref="refs"
        :form="ruleForm"
        :state_1="state_1"
        :state_2="state_2"
        :state_3="state_3"
        :state_4="state_4"
        :isShowHobby="false"
        @succeddEdit="succeddEdit"
      ></user-card>
    </my-amend>
  </div>
</template>

<script>
import myAmend from "../../components/case/amend.vue";
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";
import { debounce, throttle } from "../../components/code/common";
import EditModul from "./child/editModul.vue";
import PreviewModul from "./child/previewModul.vue";
import UserCard from "./child/userCard.vue";

import { request } from "../../API/request";
export default {
  components: { hearderInput, Pagination, myAmend, EditModul, PreviewModul, UserCard },
  data() {
    return {
      userList: [],
      // 控制相亲卡是否显示
      isUpload: false,
      // 控制编辑弹框是否显示
      isAmend: false,
      // 需要编辑的内容
      editFrom: {},
      isAmend1: false,
      // 资料预览
      previewFrom: {},
      // 分页的总条数
      total: 0,
      queryInfo: {
        page: 1, //当前页数
        size: 10, //一页显示多个条数据
      },

      isAmend2: false, // 修改内容组件

      ruleForm: {}, // 修改弹框
      edit: false, // 弹框属性默认为添加0
      editIndex: 0, // 编辑的列表索引

      state_1: '',
      state_2: '',
      state_3: '',
      state_4: '',
      wxUsername: '',
      username: ''
    };
  },
  mounted() {},
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    searchBywxUsername (e) {
      this.wxUsername = e
      this.getUserList()
    },
    searchByusername (e) {
      this.username = e
      this.getUserList()
    },
    getUserList(e) {
      request({
        url: "/customer/getWxUser",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size,
          username: this.username,
          wxUsername: this.wxUsername
        },
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.userList = res.data.records;
          console.log(this.userList);
          if(this.userList.length === 0) {
            this.$message.warning("不存在该数据");
          }
          this.total = res.data.total;
        } else {
          this.$message.error("不存在该数据");
        }
      });
    },
    isAmend3() {
      this.isAmend2 = false;
      this.$refs.refs.disabled = true;
    },
    // 修改用户信息
    extensionEdit(row, index) {
      console.log(row,'row');
      this.disabledShow = true;
      this.edit = true;
      this.editIndex = index;
      this.ruleForm = JSON.parse(JSON.stringify(row.userInfo));
      if(row.userInfo.state) {
        this.state_1 = row.userInfo.state.slice(0, 2)
        this.state_2 = row.userInfo.state.slice(2)
      }
      if(row.userInfo.hometown) {
        this.state_3 = row.userInfo.hometown.slice(0, 2)
        this.state_4 = row.userInfo.hometown.slice(2)
      }
      console.log(this.state_1, this.state_2, this.state_3, this.state_4)
      this.isAmend2 = true;
    },
    // 确认修改信息回调
    succeddEdit: debounce(function (e) {
      console.log('............', e);
      let params = {
        userName: e.userName,
        gender: e.gender,
        birthday: e.birthday,
        height: e.height,
        weight: e.weight,
        state: e.state,
        location: e.location,
        nationality: e.nationality,
        hometown: e.hometown,
        job: e.job,
        profession: e.profession,
        education: e.education,
        salary: e.salary,
        maritalStatus: e.maritalStatus,
        emotionalExp: e.emotionalExp,
        smoke: e.smoke,
        wine: e.wine,
        hobby: e.hobby,
        declaration: e.declaration,
        wxUserId: e.wxUserId,
        isExp: 1
      };
      console.log(params);
      request({
        url: "/userInfo/updateUserInfo",
        method: "POST",
        data: params
      }).then((res) => {
        console.log("编辑");
        console.log(res);
        if (res.code === 200) {
          this.$message.success("修改操作成功！");
          this.getUserList();
          this.isAmend2 = false;
        } else {
          this.$message.error(res.message);
        }
      });
    }, 300),
    // 编辑
    handleEdit(e) {
      this.isAmend = true;
      this.editFrom = e;
    },
    preview (e) {
      console.log(e);
      this.isAmend1 = true
      this.previewFrom = e
    },
    confirmPreview () {
      this.isAmend1 = false
    },
    // 确认修改信息回调
    succeddEdit1: debounce(function (e) {
      console.log(e);
      let params = {
        username: e.username,
        birthday: e.birthday.split(' ')[0],
        gender: e.gender,
        education: e.education,
        state: e.state,
        remark: e.remark,
        wxUserId: e.wxUserId,
        checkStatus: e.checkStatus,
        id: e.id,
        principal: e.principal,
        uid: e.uid
      };
      request({
        url: "/customer/edit",
        method: "POST",
        // data: e,
        data: params
      }).then((res) => {
        console.log(e,'eeeeeeeeeeee');
        console.log(res,'resres');
        if (res.code == 200) {
          this.$message.success("修改数据成功");
          this.isAmend = false;
          this.getUserList();
          return;
        }
        this.$message.error("修改数据失败");
      });
    }, 300),

    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.getUserList();
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.getUserList();
    },
  },
};
</script>

<style lang="less" scoped>
.appUser {
  background-color: #ffffff;
  padding: 24px;
  height: 93%;
  position: relative;
  .header {
    display: flex;
    .search {
      width: 250px;
    }
  }
}
.el-table {
  margin-top: 24px;
}
.operation_group {
  display: flex;
  color: #155bd4;
  cursor: pointer;
  .item {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
.isTitle {
  font-size: var(--mainSize);
  text-align: center;
  margin-bottom: 12px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  font-size: var(--subSize);
  .leftText {
    width: 100px;
  }
  .upPhotoList {
    position: relative;
    margin: 20px 20px 0 0;
    .close {
      position: absolute;
      top: -12px;
      right: -12px;
      .close_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .cardList {
    display: flex;
    flex-wrap: wrap;
  }
  .video {
    width: 180px;
    height: 300px;
    display: block;
  }
  .avatar-uploader {
    margin-top: 20px;
  }
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      border: 1px dashed #999999;
      font-size: 28px;
      color: #999999;
      width: 105px;
      height: 105px;
      line-height: 105px;
      text-align: center;
    }
  }
}
.avatar {
  width: 105px;
  height: 105px;
  display: block;
}
.btn {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  .affirm {
    background: var(--pink);
    color: var(--white);
  }
}
</style>