<template>
  <el-form v-if="previewFrom" :model="previewFrom" ref="previewFrom" label-width="110px">
    <div v-show="pages === 1">
      <div class="isTitle">预览客户信息</div>
      <el-form-item label="昵称:" prop="username">
        <el-input
          v-model="previewFrom.userInfo.userName"
          placeholder="用户姓名"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="性别：" prop="gender">
        <el-input 
          placeholder="用户性别" 
          disabled 
          v-if="previewFrom.userInfo.gender === 0" 
          :value="'女'"
        ></el-input>
        <el-input 
          placeholder="用户性别" 
          disabled 
          v-if="previewFrom.userInfo.gender === 1" 
          :value="'男'"
        ></el-input>
      </el-form-item>
      <el-form-item label="年龄：" prop="age">
        <el-input
          v-model.number="previewFrom.userInfo.age"
          placeholder="用户年龄"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="生日:" prop="birthday">
        <el-input
          v-model="previewFrom.userInfo.birthday.split(' ')[0]"
          placeholder="用户生日"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="身高:" prop="height">
        <el-input
          v-model="previewFrom.userInfo.height"
          placeholder="用户身高"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="体重:" prop="weight">
        <el-input
          v-model="previewFrom.userInfo.weight"
          placeholder="用户体重"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="居住地:" prop="state">
        <el-input
          v-model="previewFrom.userInfo.state"
          placeholder="用户居住地"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="详情地址:" prop="location">
        <el-input
          v-model.number="previewFrom.userInfo.location"
          placeholder="用户详情地址"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="国籍:" prop="locanationalitytion">
        <el-input
          v-model="previewFrom.userInfo.nationality"
          placeholder="用户国籍"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="祖籍:" prop="hometown">
        <el-input
          v-model="previewFrom.userInfo.hometown"
          placeholder="用户祖籍"
          disabled
        ></el-input>
      </el-form-item>
      
      
      <div class="skip">
        <div class="next" @click="btnNext">下一页</div>
      </div>
    </div>
    <div v-show="pages === 2">
      <div class="isTitle">预览客户信息</div>
        <el-form-item label="职业:" prop="job">
          <el-input
            v-model="previewFrom.userInfo.job"
            placeholder="用户职业"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="行业:" prop="profession">
          <el-input
            v-model="previewFrom.userInfo.profession"
            placeholder="用户行业"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="学历:" prop="education">
          <el-input
            v-model="previewFrom.userInfo.education"
            placeholder="用户学历"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="年收入:" prop="salary">
          <el-input
            v-model="previewFrom.userInfo.salary"
            placeholder="用户年收入"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="婚姻状态:" prop="maritalStatus">
          <el-input
            v-if="previewFrom.userInfo.maritalStatus === 1"
            :value="'未婚'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.maritalStatus === 2"
            :value="'已婚'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.maritalStatus === 3"
            :value="'丧偶'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.maritalStatus === 4"
            :value="'离异无孩'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.maritalStatus === 5"
            :value="'离异不带孩'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.maritalStatus === 6"
            :value="'离异带孩'"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="情感经历:" prop="emotionalExp">
          <el-input
            v-model="previewFrom.userInfo.emotionalExp"
            placeholder="用户情感经历"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="是否抽烟:" prop="smoke">
          <el-input
            v-if="previewFrom.userInfo.smoke === 0"
            :value="'不抽'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.smoke === 1"
            :value="'偶尔抽烟'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.smoke === 2"
            :value="'经常抽烟'"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="是否喝酒:" prop="wine">
          <el-input
            v-if="previewFrom.userInfo.wine === 0"
            :value="'不喝'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.wine === 1"
            :value="'偶尔喝酒'"
            disabled
          ></el-input>
          <el-input
            v-if="previewFrom.userInfo.wine === 2"
            :value="'经常喝酒'"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="兴趣爱好:" prop="hobby">
          <el-input
            v-model="previewFrom.userInfo.hobby"
            placeholder="用户兴趣爱好"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="内心独白:" prop="declaration">
          <el-input
            v-model="previewFrom.userInfo.declaration"
            placeholder="用户内心独白"
            disabled
          ></el-input>
        </el-form-item>
      <div class="skip">
        <div class="up" @click="btnUp">上一页</div>
        <div class="affirm" @click="submitAffirm">确认</div>
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    form: {
      type: Object
    }
  },
  data() {
    return {
      previewFrom: null,
      pages: 1, // 页数
    };
  },
  mounted() {
  },
  methods: {
    submitAffirm () {
      this.$emit('confirmPreview')
    },
    // 上一页
    btnUp() {
      this.pages = this.pages - 1;
    },
    // 下一页
    btnNext() {
      this.pages = this.pages + 1;
    }
  },
  watch: {
    // 监听传值默认页数为一
    form(newVal) {
      this.pages = 1;
      this.previewFrom = newVal
    }
  },
};
</script>

<style lang="less" scoped>
.el-form {
  .el-form-item {
    margin-bottom: 18px;
    /deep/.el-form-item__label {
      font-size: 16px;
      text-align: start;
    }
    .itemVal {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .isTitle {
    font-size: var(--mainSize);
    text-align: center;
    margin-bottom: 12px;
  }
  .skip {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
    font-size: var(--minSize);
    color: var(--info);
    .next {
      cursor: pointer;
      margin-left: 20px;
      &:hover {
        color: var(--subPink);
      }
    }
    .up {
      cursor: pointer;

      &:hover {
        color: var(--subPink);
      }
    }
    .affirm {
      margin-left: 20px;
      padding: 5px 12px;
      cursor: pointer;
      background: var(--pink);
      color: var(--white);
      border-radius: 3px;
    }
  }
}
</style>